<template>
  <div>
    <b-card>
      <b-form inline @submit="onSubmit" @reset="onReset">
        <h5 class="title">ส่วนค้นหาข้อมูล:</h5>
        <!-- ชื่อสินค้าภาษาไทย -->
        <b-input-group class="ml-1p">
          <b-input-group-prepend is-text>
            {{ bank }}
          </b-input-group-prepend>
          <!-- Input -->
          <b-form-input
            id="name-thai"
            :placeholder="bank"
            v-model="form.bank"
          />
        </b-input-group>
        <!-- ชื่อสินค้าภาษาอังกฤษ -->
        <b-input-group class="ml-1p">
          <b-input-group-prepend is-text>
            {{ accountNoFromBank }}
          </b-input-group-prepend>
          <!-- Input -->
          <b-form-input
            id="name-english"
            :placeholder="accountNoFromBank"
            v-model="form.accountNoFromBank"
          />
        </b-input-group>
        <!-- หมายเลขสินค้า -->
        <b-input-group class="ml-1p">
          <b-input-group-prepend is-text>
            {{ accountRefFromBank }}
          </b-input-group-prepend>
          <!-- Input -->
          <b-form-input
            id="product-number"
            :placeholder="accountRefFromBank"
            v-model="form.accountRefFromBank"
          />
        </b-input-group>
        <b-button type="submit" variant="primary" class="ml-2p">
          {{ search }}
        </b-button>
        <b-button type="reset" variant="secondary" class="ml-1p">
          {{ reset }}
        </b-button>
      </b-form>
    </b-card>
  </div>
</template>

<script>
import {
  // Form
  BForm,
  BFormInput,
  // BFormGroup,
  BInputGroup,
  // Card
  BCard,
  // Button
  BButton,
  // Select
  BFormSelect,
  // Prepend
  BInputGroupPrepend,
} from "bootstrap-vue";

export default {
  components: {
    // Form
    BForm,
    BFormInput,
    // BFormGroup,
    BInputGroup,
    // Card
    BCard,
    // Button
    BButton,
    // Selected
    BFormSelect,
    // Prepend
    BInputGroupPrepend,
  },
  data() {
    return {
      // Form
      form: {
        bank: "",
        accountNoFromBank: "",
        accountRefFromBank: "",
      },
      bank: "ชื่อธนาคาร",
      accountNoFromBank: "เลขบัญชี",
      accountRefFromBank: "ข้อมูลอ้างอิงจากธนาคาร",
      search: "ค้นหา",
      reset: "ล้างค่า",
      searchBtn: "",
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      this.$emit("SubmitData", this.form);
      // alert(JSON.stringify(this.form));
    },
    onReset(evt) {
      evt.preventDefault();
      // Reset our form values
      this.form.bank = "";
      this.form.accountNoFromBank = "";
      this.form.accountRefFromBank = "";
    },
  },
};
</script>

<style scoped>
.ml-1p {
  margin-left: 1%;
}
.ml-2p {
  margin-left: 2%;
}
.title{
  display: contents;
  font-weight: bold;
}
</style>
