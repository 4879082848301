<template>
  <div>
    <a @click="modalShow = !modalShow">
      <b-overlay :show="loading" rounded="sm">
        <statistic-card-with-area-chart
          icon="TrendingUpIcon"
          title="ยอดรวมทุกธนาคาร"
          color="primary"
          :statistic="
            `${sumAmount
              .toString()
              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} บาท`
          "
          statistic-title="จำนวนเงินทั้งหมด"
        />
      </b-overlay>
    </a>

    <log-modal
      :show="modalShow"
      @close="closeModal"
      :list="listForModal"
    />
  </div>
</template>

<script>
import { BOverlay } from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import configFunction from "@/constants/functional";
import LogModal from "./LogModal.vue";

export default {
  components: {
    StatisticCardWithAreaChart,
    BOverlay,
    LogModal,
  },
  data() {
    return {
      loading: false,
      sumAmount: 0,
      amountList: [{ data: [] }],
      modalShow: false,
      listForModal: [],
    };
  },
  // created() {

  // },
  mounted() {
    this.fetchData();
    // Get Data
    setInterval(() => {
      this.fetchData();
    }, configFunction.refreshTime);
  },
  methods: {
    kFormatter,
    fetchData() {
      this.loading = true;
      this.sumAmount = 0;
      useJwt
        .query("/check-transfers", "_limit=-1")
        .then((res) => {
          if (res.status === 200) {
            this.listForModal = res.data.sort((a,b) => {return new Date(b.created_at) - new Date(a.created_at)} )
            res && res.data && res.data.length > 0
              ? (this.amountList[0].data = res.data.map((item, index) => {
                  if (item.typeFromBank === "in") {
                    this.sumAmount += item.amountFromBank
                      ? item.amountFromBank
                      : 0;
                  } else {
                    this.sumAmount -= item.amountFromBank
                      ? item.amountFromBank
                      : 0;
                  }
                  return item.amountFromBank ? item.amountFromBank : 0;
                }))
              : (this.amountList = [{ data: [] }]);
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "เกิดข้อผิดพลาด",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `${err}`,
            },
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    closeModal() {
      this.modalShow = false;
    },
  },
};
</script>

<style></style>
