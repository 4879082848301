<template>
  <div>
    <!-- Dashboard Summary -->
    <b-row>
      <b-col cols="3">
        <summary-all ref="sumAll" />
      </b-col>
      <b-col cols="3">
        <summary-all-today ref="sumAllToday" />
      </b-col>
    </b-row>
    <b-card title="รายการเงินเข้าล่าสุด">
      <b-overlay :show="loading" rounded="sm">
        <!-- search input -->
        <div>
          <SearchComponent @SubmitData="searchSubmit" />
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="transferList"
          :rtl="direction"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            //enabled: true,
            //selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
            //selectionInfoClass: 'custom-class',
            //selectionText: 'rows selected',
            //clearSelectionText: 'clear',
            //disableSelectInfo: true, // disable the select info panel on top
            //selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          @on-selected-rows-change="selectionChanged"
          @on-page-change="paginationFunc"
          @on-per-page-change="itemPerpageFunc"
          @on-sort-change="sortFunc"
        >
          <!-- Selected: Delete -->
          <div slot="selected-row-actions">
            <b-button variant="gradient-danger">
              ลบ
            </b-button>
          </div>
          <template slot="table-row" slot-scope="props">
            <!-- Column: amountFromBank -->
            <span
              v-if="props.column.field === 'amountFromBank'"
              class="text-nowrap"
            >
              {{ props.row.amountFromBank.toLocaleString() }}
            </span>

            <span v-else-if="props.column.field === 'bank'" class="text-nowrap">
              <img
                :src="
                  require(`@/assets/images/bank/${props.row.bank.toLowerCase()}.png`)
                "
                width="30"
                style="margin-right:10px"
              />
              {{ props.row.bank.toUpperCase() }}
            </span>

            <!-- Column: Action -->
            <!-- <span v-else-if="props.column.field === 'action'">
            <span>
              <b-button
                variant="gradient-primary"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'ดูรายละเอียด'"
                :to="`payment-detail/${props.row.id}`"
              >
                <feather-icon icon="EyeIcon" />
              </b-button>
              <b-button
                variant="gradient-warning"
                class="btn-icon rounded-circle"
                style="margin: 5px"
                v-b-tooltip.hover.top="'ส่งข้อมูล'"
                :disabled="btnLoading"
                @click="sendData"
              >
                <b-spinner small v-if="btnLoading" />
                <feather-icon icon="Link2Icon" v-else />
              </b-button>
            </span>
          </span> -->

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>

          <!-- Column: loading -->
          <template slot="loadingContent">
            <b-spinner variant="primary" label="Text Centered" />
          </template>

          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  แสดงข้อมูล 1 ถึง
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="perPageValue"
                  class="mx-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap">
                  ทั้งหมด {{ totalCount }} ข้อมูล
                </span>
              </div>
              <div>
                <b-pagination
                  :value="pageValue"
                  :total-rows="totalCount"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-overlay>
    </b-card>
    <SummaryEachToday />
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BSpinner,
  VBTooltip,
  BCard,
  BCardText,
  BOverlay,
  BRow,
  BCol,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import moment from "moment";
// import dataDummy from "./data";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BaseAPIURL from "@core/app-config/baseURL";
import perPageValue from "@/constants/perPagedropdown";
import configFunction from "@/constants/functional";
import store from "@/store/index";
import SearchComponent from "../components/Search.vue";
import SummaryAll from "../components/SummaryAll.vue";
import SummaryAllToday from "../components/SummaryAllToday.vue";
import SummaryEachToday from "../components/SummaryEachToday.vue";
// import ChartjsDoughnutChart from '../components/ChartjsDoughnutChart.vue'
// import ChartjsPolarAreaChart from '../components/ChartjsPolarAreaChart.vue'
// import ChartjsBarChart from '../components/ChartjsBarChart.vue'

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BButton,
    BSpinner,
    BCard,
    BCardText,
    SearchComponent,
    SummaryAll,
    SummaryAllToday,
    SummaryEachToday,
    BOverlay,
    BRow,
    BCol,
    // ChartjsDoughnutChart,
    // ChartjsPolarAreaChart,
    // ChartjsBarChart,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  props: {
    // vendorList: Array,
    // table: String,
    // where: String,
  },
  data() {
    return {
      loading: false,
      btnLoading: false,
      // API
      collectionAPI: "/check-transfers", // ชื่อ API
      whereLimit: "&_limit=", // condition  ตั้งต้น, ตัวอย่างการเขียน -> "&_limit=5",
      whereSortList: [],
      whereSortCondition: "",
      wherePagination: "",
      whereSearchCondition: "",
      whereQueryDefaultCondition: "&_sort=created_at:desc", // Qeury เริ่มต้น, ตัวอย่างการเขียน -> "&delete=false",
      whereCondition: "",
      // Table
      pageLength: 0,
      pageValue: 1,
      dir: false,
      columns: [
        {
          label: "ธนาคาร",
          field: "bank",
        },
        {
          label: "เลขบัญชี",
          field: "accountNoFromBank",
        },

        {
          label: "จำนวนเงิน (บาท)",
          field: "amountFromBank",
          type: "number",
          tdClass: "text-left",
        },
        {
          label: "ราละเอียด",
          field: "descriptionFromBank",
        },

        {
          label: "ข้อมูลอ้างอิงจากธนาคาร",
          field: "accountRefFromBank",
        },
        {
          label: "วันเวลาที่โอนเงินจากธนาคาร",
          field: "dateFromBank",
        },
        {
          label: "วันเวลาสร้างข้อมูล",
          field: "created_at",
        },
        // {
        //   label: "Action",
        //   field: "action",
        // },
      ],
      rows: [],
      searchTerm: "",
      // transferList: dataDummy.transferList, // import from data Dummy
      transferList: [],
      totalCount: 0,
      moment,
      BaseAPIURL,
      perPageValue,
      bankList: [],
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    // Set Page Data
    this.pageLength = this.perPageValue[0];
    this.whereLimit = `${this.whereLimit}${this.perPageValue[0]}`;
    // Get Data
    this.getDataTotal();
    this.fetchData();
    // this.fetchDataBankList()
  },
  mounted() {
    // Get Data
    setInterval(() => {
      this.getDataTotal();
      this.fetchData();
    }, configFunction.refreshTime);
  },
  methods: {
    selectionChanged(e) {
      console.log(e);
    },
    sendData() {
      this.btnLoading = true;
    },
    getDataTotal() {
      this.whereCondition = `${
        this.whereLimit && this.whereLimit !== "" ? this.whereLimit : ""
      }${
        this.whereSortCondition && this.whereSortCondition !== ""
          ? this.whereSortCondition
          : ""
      }${
        this.wherePagination && this.wherePagination !== ""
          ? this.wherePagination
          : ""
      }${
        this.whereSearchCondition && this.whereSearchCondition !== ""
          ? this.whereSearchCondition
          : ""
      }${
        this.whereQueryDefaultCondition &&
        this.whereQueryDefaultCondition !== ""
          ? this.whereQueryDefaultCondition
          : ""
      }`;

      return useJwt
        .get(this.collectionAPI, `count?${this.whereCondition}`)
        .then((res) => {
          if (res.status === 200) {
            return (this.totalCount = res.data);
          }
          throw res.data.message;
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Not Found",
              icon: "XCircleIcon",
              variant: "danger",
              text: `${err}`,
            },
          });
        });
    },
    fetchDataBankList() {
      this.loading = true;
      return useJwt
        .query("/account-msts", "_limit=-1")
        .then((res) => {
          if (res.status === 200) {
            console.log("resBank", res);
            // eslint-disable-next-line no-unused-expressions
            res && res.data && res.data.length > 0
              ? (this.bankList = res.data)
              : null;
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: err,
            },
          });
        })
        .finally(() => {
          this.loading = false;
          // console.log("this.transferList", this.transferList);
        });
    },
    fetchData() {
      this.loading = true;

      // รวม Query
      this.whereCondition = `${
        this.whereLimit && this.whereLimit !== "" ? this.whereLimit : ""
      }${
        this.whereSortCondition && this.whereSortCondition !== ""
          ? this.whereSortCondition
          : ""
      }${
        this.wherePagination && this.wherePagination !== ""
          ? this.wherePagination
          : ""
      }${
        this.whereSearchCondition && this.whereSearchCondition !== ""
          ? this.whereSearchCondition
          : ""
      }${
        this.whereQueryDefaultCondition &&
        this.whereQueryDefaultCondition !== ""
          ? this.whereQueryDefaultCondition
          : ""
      }`;

      return useJwt
        .query(this.collectionAPI, this.whereCondition)
        .then((res) => {
          if (res.status === 200) {
            console.log("res", res);
            // eslint-disable-next-line no-unused-expressions
            res && res.data && res.data.length > 0
              ? (this.transferList = res.data.map((item, index) => ({
                  id: item.id ? item.id : "",
                  bank: item.bank ? item.bank : "",
                  amountFromBank: item.amountFromBank
                    ? item.amountFromBank
                    : "",
                  descriptionFromBank: item.descriptionFromBank
                    ? item.descriptionFromBank
                    : "",
                  dateFromBank: item.dateFromBank ? item.dateFromBank : "",
                  accountNoFromBank: item.accountNoFromBank
                    ? item.accountNoFromBank
                    : "",
                  accountRefFromBank: item.accountRefFromBank
                    ? item.accountRefFromBank
                    : "",
                  created_at: moment(item.created_at)
                    .locale("th")
                    .format("Do MMMM YYYY HH:mm น."), // moment(item.dateFromBank).locale('th').format('Do MMMM YYYY HH:mm น.')
                })))
              : (this.transferList = []);
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Error",
              icon: "BellIcon",
              variant: "danger",
              text: err,
            },
          });
        })
        .finally(() => {
          this.loading = false;
          // console.log("this.transferList", this.transferList);
        });
    },
    searchSubmit(e) {
      this.loading = true;
      const searchData = JSON.parse(JSON.stringify(e));
      const bank =
        searchData && searchData.bank ? `bank_contains=${searchData.bank}` : "";
      const accountNoFromBank =
        searchData && searchData.accountNoFromBank
          ? `accountNoFromBank_contains=${searchData.accountNoFromBank}`
          : "";
      const accountRefFromBank =
        searchData && searchData.accountRefFromBank
          ? `accountRefFromBank_contains=${searchData.accountRefFromBank}`
          : "";
      // ต้องเรียงเอง
      this.whereSearchCondition = `${bank ? `&${bank}` : ""}${
        accountNoFromBank ? `&${accountNoFromBank}` : ""
      }${accountRefFromBank ? `&${accountRefFromBank}` : ""}`;

      this.wherePagination = "";

      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    itemPerpageFunc(data) {
      this.loading = true;
      this.whereLimit = `&_limit=${data.currentPerPage}`;
      this.wherePagination = "";

      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    paginationFunc(data) {
      this.loading = true;
      if (data.currentPage > 1) {
        this.wherePagination = `&_start=${(data.currentPage - 1) *
          this.pageLength}`;
      } else {
        this.wherePagination = "";
      }

      this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
    sortFunc(data) {
      this.loading = true;
      this.whereSortCondition = "&_sort=";
      for (let i = 0; i < data.length; i++) {
        if (data.length === 1 || i === data.length - 1) {
          this.whereSortCondition += `${data[i].field}:${data[i].type}`;
        } else if (i < data.length - 1) {
          this.whereSortCondition = `${this.whereSortCondition}${data[i].field}:${data[i].type},`;
        } else {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Unsuccessful",
              icon: "XCircleIcon",
              variant: "danger",
              text: "เกิดเหตุการณ์ผิดปกติ กรุณา refresh หน้าจอ",
            },
          });
          return false;
        }
      }
      return this.getDataTotal().then(() => {
        this.fetchData();
      });
    },
  },
};
</script>
