<template>
  <div>
    <!-- basic modal -->
    <b-modal
      v-model="show"
      title="รายการยอดเงิน"
      ok-only
      ok-title="ปิด"
      ok-variant="danger"
      @hidden="close"
      size="lg"
    >
      <b-card-text>
        <div style="margin-bottom: 5%">
          <img
            :src="
              require(`@/assets/images/bank/${list[0].bank.toLowerCase()}.png`)
            "
            v-if="list[0] && list[0].bank"
            width="60"
            style="margin-right:10px"
          />
          หมายเลขบัญชี : {{ list[0].accountNoFromBank }}
        </div>
        <div style="overflow: auto">
          <b-table
            id="my-table"
            :per-page="perPage"
            :current-page="currentPage"
            :items="list"
            :fields="fields"
          >
            <template #cell(amountFromBank)="data">
              <span
                class="text-nowrap"
                v-if="data.item.typeFromBank === 'in'"
                style="color : green"
              >
                +{{ data.value.toLocaleString() }}
              </span>
              <span
                class="text-nowrap"
                v-else-if="data.item.typeFromBank === 'out'"
                style="color : red"
              >
                -{{ data.value.toLocaleString() }}
              </span>
            </template>
            <template #cell(typeFromBank)="data">
              <span class="text-nowrap">
                {{
                  data.value === "in"
                    ? "ฝากเงิน"
                    : data.value === "out"
                    ? "ถอนเงิน"
                    : ""
                }}
              </span>
            </template>
            <template #cell(created_at)="data">
              <span class="text-nowrap">
                {{
                  moment(data.value)
                    .locale("th")
                    .format("Do MMMM YYYY HH:mm น."),
                }}
              </span>
            </template>
          </b-table>
        </div>
        <span> ข้อมูลทั้งหมด : {{ rows }}</span>

        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          aria-controls="my-table"
          style="float: right"
        ></b-pagination>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  BButton,
  VBModal,
  BAlert,
  BCardText,
  BTable,
  BPagination,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import moment from "moment";

export default {
  components: {
    BCardText,
    BButton,
    BModal,
    BAlert,
    BTable,
    BPagination,
  },
  props: {
    show: Boolean,
    list: Array,
    account: String,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  computed: {
    rows() {
      return this.list.length;
    },
  },
  updated() {
    console.log("list for sort", this.list);
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      moment,
      fields: [
        {
          label: "จำนวนเงิน (บาท)",
          key: "amountFromBank",
        },
        {
          label: "ราละเอียด",
          key: "descriptionFromBank",
        },
        {
          label: "ข้อมูลอ้างอิงจากธนาคาร",
          key: "accountRefFromBank",
        },
        {
          label: "ประเภท",
          key: "typeFromBank",
        },
        {
          label: "วันเวลาสร้างข้อมูล",
          key: "created_at",
        },
      ],
      listForTable: [],
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
