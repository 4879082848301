<template>
  <b-row>
    <b-col v-for="item in this.bankList" :key="item.accountNoFromBank" cols="3">
      <a @click="showModal(item.accountNoFromBank)">
        <b-overlay :show="loading" rounded="sm">
          <statistic-card-with-area-chart
            icon-img="kbank"
            color="success"
            :title="item.accountNoFromBank"
            :statistic="
              `${item.amountFromBank
                .toString()
                .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')} บาท`
            "
            statistic-title="จำนวนเงินวันนี้"
          />
        </b-overlay>
      </a>
    </b-col>
    <log-modal-dynamic
      :show="modalShow"
      @close="closeModal"
      :list="listForModal.filter(
        ({ accountNoFromBank }) => accountNoFromBank === accountForModal
      ).sort((a,b) => {return new Date(b.created_at) - new Date(a.created_at)} )"
    />
  </b-row>
</template>

<script>
import { BOverlay, BCard, BCardText, BRow, BCol } from "bootstrap-vue";
import { kFormatter } from "@core/utils/filter";
import StatisticCardWithAreaChart from "@core/components/statistics-cards/StatisticCardWithAreaChart.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useJwt from "@/auth/jwt/useJwt";
import configFunction from "@/constants/functional";
import LogModalDynamic from "./LogModalDynamic.vue";

export default {
  components: {
    StatisticCardWithAreaChart,
    BCard,
    BCardText,
    BOverlay,
    BRow,
    BCol,
    LogModalDynamic,
  },
  data() {
    return {
      loading: false,
      bankList: [],
      modalShow: false,
      listForModal: [],
      accountForModal: "",
    };
  },
  created() {
    this.fetchData();
  },
  mounted() {
    // Get Data
    setInterval(() => {
      this.fetchData();
    }, configFunction.refreshTime);
  },
  methods: {
    kFormatter,
    fetchData() {
      this.loading = true;
      this.sumAmount = 0;
      useJwt
        .query(
          "/check-transfers",
          `created_at_gte=${new Date(Date.now() - 3600 * 1000 * 24)
            .toISOString()
            .substr(0, 10)}T00:00:00.000Z&_limit=-1`
        )
        .then((res) => {
          if (res.status === 200) {
            this.listForModal = res.data;
            const sumArray = Array.from(
              res.data.reduce(
                (m, { accountNoFromBank, amountFromBank, typeFromBank }) =>
                  m.set(
                    accountNoFromBank,
                    (m.get(accountNoFromBank) || 0) +
                      (typeFromBank === "out"
                        ? -Math.abs(amountFromBank)
                        : amountFromBank)
                  ),
                new Map()
              ),
              ([accountNoFromBank, amountFromBank]) => ({
                accountNoFromBank,
                amountFromBank,
              })
            );

            this.bankList = sumArray;
          } else {
            throw res.data.message;
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "เกิดข้อผิดพลาด",
              icon: "CheckCircleIcon",
              variant: "success",
              text: `${err}`,
            },
          });
        })
        .finally(() => {
          this.loading = false;
          // console.log("bank list", this.listForModal);
        });
    },
    showModal(account) {
      this.accountForModal = account;
      this.modalShow = true;
    },
    closeModal() {
      this.accountForModal = "";
      this.modalShow = false;
    },
  },
};
</script>

<style></style>
